<app-title title="Meine Anfragen" [actionButton]="actionButton"></app-title>

<app-row justify="end" class="mt-2">
  <app-col>
    <button
      [attr.aria-expanded]="showFilters"
      aria-controls="show-filters"
      mat-icon-button
      color="primary"
      (click)="showFilters = !showFilters"
    >
      <mat-icon> filter_list </mat-icon>
      <span class="cdk-visually-hidden">Filter anzeigen</span>
    </button>
  </app-col>
</app-row>

<app-expander-animation
  [isExpanded]="showFilters"
  id="show-filters"
  [formGroup]="filters"
>
  <app-row class="mb-5">
    <app-col xs="12">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Standort</mat-label>
        <mat-select formControlName="locationFilter" name="location">
          <mat-option value="">Alle</mat-option>

          <mat-option disabled *ngIf="!(locations$ | async)?.length">
            Lade…
          </mat-option>

          <mat-option
            *ngFor="let location of locations$ | async"
            [value]="location.id"
          >
            {{ location.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </app-col>

    <app-col xs="12">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Status</mat-label>
        <mat-select
          [multiple]="true"
          formControlName="inquiryStatus"
          name="inquiryStatus"
        >
          <ng-container *ngFor="let status of INQUIRY_STATUSES">
            <mat-option *ngIf="status !== 'OFFERS'" [value]="status">
              {{ inquiryStatusMap[status]?.name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </app-col>

    <app-col xs="12">
      <mat-slide-toggle formControlName="onlyWithOffers">
        Nur Anfragen mit Angeboten anzeigen
      </mat-slide-toggle>
    </app-col>
  </app-row>
</app-expander-animation>

<app-row class="mt-4">
  <app-col xs="12">
    <app-loading *ngIf="inquiries$ | loading | async" />
    <app-empty-placeholder
      *ngIf="
        !(inquiries$ | async)?.length &&
        (inquiries$ | loading | async) === false
      "
    >
      Anfragen
    </app-empty-placeholder>
  </app-col>

  <ng-container *ngIf="filteredInquiries$ | async as inquiries">
    <ng-container *ngFor="let inquiry of inquiries">
      <ng-container *ngIf="isQuotationValid(inquiry.quotationDeadline)">
        <app-col xs="12" class="mb-5">
          <app-producer-inquiry-card
            [inquiry]="inquiry"
            (reload)="fetchInquiries()"
          ></app-producer-inquiry-card>
        </app-col>
      </ng-container>
    </ng-container>
  </ng-container>
</app-row>

<app-title
  title="Bereits abgelaufene Anfragen"
  *ngIf="(filteredInquiries$ | async)?.length"
></app-title>

<app-row class="mt-4">
  <ng-container
    *ngIf="filteredInquiries$ | async as inquiries"
    class="mouseflow-ignore"
  >
    <ng-container *ngFor="let inquiry of inquiries">
      <ng-container *ngIf="isQuotationExpired(inquiry.quotationDeadline)">
        <app-col xs="12" class="mb-5">
          <app-producer-inquiry-card
            [inquiry]="inquiry"
            (reload)="fetchInquiries()"
          ></app-producer-inquiry-card>
        </app-col>
      </ng-container>
    </ng-container>
  </ng-container>
</app-row>
