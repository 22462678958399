import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inquiry } from 'src/app/models/inquiry';
import { WarningpopUpComponent } from 'src/app/components/shared/warningpop-up/warningpop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { InquiryService } from 'src/app/shared/services/inquiry.service';
import { Router, RouterModule } from '@angular/router';
import { ExpandableCardComponent } from 'src/app/components/shared/expandable-card/expandable-card.component';
import { RowComponent } from 'src/app/components/shared/row/row.component';
import { ColComponent } from 'src/app/components/shared/col/col.component';
import { InquiryStatusBadgeComponent } from 'src/app/components/matchmaking/inquiry-status-badge/inquiry-status-badge.component';
import { CardTitleComponent } from 'src/app/components/shared/card-title/card-title.component';
import { RoundIconComponent } from 'src/app/components/shared/round-icon/round-icon.component';
import { WastePillsComponent } from 'src/app/components/matchmaking/waste-pills/waste-pills.component';
import { SpacerComponent } from 'src/app/components/shared/spacer/spacer.component';
import { mapTypeToLabel } from 'src/app/shared/utils/inquiries';
import { Observable, map } from 'rxjs';
import { Location } from 'src/app/models/location';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { UserService, User } from 'src/app/shared/services/user.service';
import { ExtendDeadlineComponent } from 'src/app/components/matchmaking/extend-deadline/extend-deadline.component';
import { OfferService } from 'src/app/shared/services/offer.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { convertPreferredContractDuration } from 'src/app/shared/utils/adapters';
import { QnAService } from 'src/app/shared/services/dwrm/qna/qna.service';

@Component({
  selector: 'app-producer-inquiry-card',
  standalone: true,
  templateUrl: './producer-inquiry-card.component.html',
  styleUrls: ['./producer-inquiry-card.component.scss'],
  imports: [
    CommonModule,
    AppMaterialModule,
    ExpandableCardComponent,
    RowComponent,
    ColComponent,
    InquiryStatusBadgeComponent,
    CardTitleComponent,
    RoundIconComponent,
    WastePillsComponent,
    SpacerComponent,
    RouterModule,
  ],
})
export class ProducerInquiryCardComponent implements OnChanges, OnInit {
  @Input() inquiry: Inquiry;
  @Output() reload = new EventEmitter();

  router = inject(Router);
  dialog = inject(MatDialog);
  inquiryService = inject(InquiryService);
  locationService = inject(LocationService);
  qnaService = inject(QnAService);
  offerService = inject(OfferService);
  userService = inject(UserService);
  mapTypeToLabel = mapTypeToLabel;
  convertPreferredContractDuration = convertPreferredContractDuration;
  location$: Observable<Location>;
  user$: Observable<User>;
  numberOfOffers$: Observable<number>;

  hasUnansweredQnas: boolean;

  ngOnInit() {
    this.qnaService
      .getListQnAsByInquiryId(this.inquiry.id)
      .subscribe((qnas) => {
        if (qnas.length > 0) {
          this.hasUnansweredQnas = qnas.some(
            (qna) =>
              qna.verifiedQuestion === true &&
              (qna.answer === null || qna.answer === undefined)
          );
        } else {
          this.hasUnansweredQnas = false;
        }
      });
  }

  ngOnChanges() {
    this.location$ = this.locationService.getLocationById(
      this.inquiry.locationId
    );

    this.user$ = this.userService.getUser(this.inquiry.userId);
    this.numberOfOffers$ = this.offerService
      .getOffersForInquiry(this.inquiry.id)
      .pipe(map((offers) => offers.length));
  }

  showInquiryDetails(id: string) {
    this.router.navigateByUrl(`/erzeuger-anfragen/${id}`);
  }

  extendInquiryDeadline() {
    const dialogRef = this.dialog.open(ExtendDeadlineComponent, {
      data: {
        inquiry: this.inquiry,
      },
    });

    dialogRef.afterClosed().subscribe((newInquiry: Inquiry) => {
      if (newInquiry) {
        this.inquiry = newInquiry;
      }
    });
  }

  removeInquiry(id: string) {
    const dialogRef = this.dialog.open(WarningpopUpComponent, {
      data: {
        message: 'Wirklich entfernen?',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.inquiryService
          .patchInquiry(id, {
            status: 'RETRACTED',
          })
          .subscribe(() => {
            this.reload.emit();
          });
      }
    });
  }
}
